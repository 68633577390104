import { DocumentsSettings } from '@features/settings';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { UploadButton } from '@shared/ui/uploadButton';
import { SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentBannerConfiguration, DocumentUpload } from '../documentUpload';
import { useDocumentUpload } from '../documentUpload/useDocumentUpload';
import { ChatMessage } from '../domain';
import { TokenCounter } from '../tokenCounter';

type Props = {
    sendMessage: (value: string) => void;
    isSendingQuestion: boolean;
    maxTokens: number;
    tokenCounterInfoUrl: string;
    setDivMessageBoxHeight: (value: number) => void;
    isFocused: boolean;
    setIsFocused: (value: boolean) => void;
    documentSettings: DocumentsSettings;
    chatId: string;
    setChatId: (value: string) => void;
    setMessages: (value: SetStateAction<ChatMessage[]>) => void;
    setDisableDocumentUpload: (value: boolean) => void;
    disableDocumentUpload: boolean;
    setManageBanner: (value: SetStateAction<DocumentBannerConfiguration>) => void;
};

const MessageBox = ({
    sendMessage,
    isSendingQuestion,
    maxTokens,
    tokenCounterInfoUrl,
    setDivMessageBoxHeight,
    isFocused,
    setIsFocused,
    documentSettings,
    chatId,
    setChatId,
    setMessages,
    setDisableDocumentUpload,
    disableDocumentUpload,
    setManageBanner,
}: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [message, setMessage] = useState('');
    const [disableSendingQuestion, setDisableSendingQuestion] = useState(false);
    const [isDraggedInMessageBoxWrapper, setIsDraggedInMessageBoxWrapper] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const divWrapperRef = useRef<HTMLDivElement>(null);

    const disableSendButton = isSendingQuestion || disableSendingQuestion || message === '';
    const {
        isFileDraggedInScreen,
        isDraggingInsideZone,
        uploadingDocStatus,
        setIsDraggingInsideZone,
        setIsFileDraggedInScreen,
        handleChangeUpload,
        handleFileErrors,
    } = useDocumentUpload({
        setMessages,
        setChatId,
        isDraggedInMessageBoxWrapper,
        chatId,
        setDisableDocumentUpload,
        setErrorMessage,
    });

    const clickHandler = () => {
        const messageTrimmed = message.trim();
        if (messageTrimmed !== '') {
            sendMessage(messageTrimmed);
            setMessage('');
            setIsFocused(true);
        }
    };

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isReady = !isSendingQuestion && !disableSendingQuestion;
        const sendKeyPressed = e.key === 'Enter' && !e.shiftKey;
        if (sendKeyPressed && isReady) {
            clickHandler();
            e.preventDefault();
        } else {
            errorMessage !== '' && setErrorMessage('');
        }
    };

    useLayoutEffect(() => {
        if (isFocused && !isSendingQuestion) {
            inputRef?.current?.focus();
            setIsFocused(false);
        }
    }, [isFocused, isSendingQuestion]);

    useLayoutEffect(() => {
        setDivMessageBoxHeight(divWrapperRef.current!.scrollHeight);
    }, [message]);

    useEffect(() => {
        const showBanner = uploadingDocStatus === 'started' || uploadingDocStatus === 'finished';
        setManageBanner({
            show: showBanner,
            status: uploadingDocStatus,
        });
        setErrorMessage('');
    }, [uploadingDocStatus]);

    return (
        <>
            <Box
                maxWidth={1024}
                minHeight={72}
                margin="auto"
                bottom={0}
                alignSelf="end"
                position="fixed"
                right={0}
                left={0}
                sx={{ userSelect: 'none' }}
                marginBottom={6}
                ref={divWrapperRef}
                onDragEnter={() => setIsDraggedInMessageBoxWrapper(true)}
                onDragLeave={() => setIsDraggedInMessageBoxWrapper(false)}
            >
                <Box borderRadius={1} bgcolor={grey[300]} zIndex={1} position="relative">
                    <Box display="flex" alignItems="center" width="100%" position="relative">
                        <TextField
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder={t('chat.textfieldPlaceholder')}
                            data-testid="text-field-message-box"
                            sx={{
                                bgcolor: grey[300],
                                minHeight: 52,
                                maxHeight: 250,
                                overflow: 'auto',
                                padding: 2,
                                paddingRight: 16,
                                paddingBottom: 0,
                                marginBottom: 1,
                                borderRadius: 1,
                            }}
                            onChange={e => setMessage(e.target.value)}
                            onKeyDown={keyDownHandler}
                            value={message}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            disabled={isSendingQuestion}
                            autoFocus
                            inputRef={inputRef}
                        />
                        <Button
                            type="submit"
                            onClick={clickHandler}
                            sx={{
                                minWidth: 40,
                                maxHeight: 40,
                                minHeight: 40,
                                marginX: 2,
                                position: 'absolute',
                                bottom: 8,
                                right: 0,
                                bgcolor: theme.palette.primary.main,
                                '&:hover': { bgcolor: theme.palette.primary.dark },
                                '&:disabled': { bgcolor: grey[400] },
                            }}
                            disabled={disableSendButton}
                        >
                            <SendIcon sx={{ color: 'white' }} />
                        </Button>
                        <UploadButton
                            handleChange={handleChangeUpload}
                            documentSettings={documentSettings}
                            isDisabled={disableDocumentUpload}
                            handleErrors={handleFileErrors}
                        />
                    </Box>

                    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" flex={1}>
                        {errorMessage !== '' && (
                            <Box
                                display="flex"
                                justifyContent="start"
                                alignItems="center"
                                width="100%"
                                color={theme.palette.error.main}
                                paddingLeft={2}
                                paddingBottom={1}
                                gap={0.5}
                            >
                                <ErrorOutlineOutlinedIcon sx={{ width: 16 }} />
                                <Typography variant="caption">{errorMessage}</Typography>
                            </Box>
                        )}
                        <TokenCounter
                            maxTokens={maxTokens}
                            text={message}
                            tokenCounterInfoUrl={tokenCounterInfoUrl}
                            setDisableSendingQuestion={setDisableSendingQuestion}
                        />
                    </Box>
                </Box>
                <DocumentUpload
                    formatsAllowed={documentSettings.formatsAllowed}
                    docSize={documentSettings.size}
                    isDisabled={disableDocumentUpload}
                    handleChangeUpload={handleChangeUpload}
                    handleErrors={handleFileErrors}
                    isDraggingInsideZone={isDraggingInsideZone}
                    isFileDraggedInScreen={isFileDraggedInScreen}
                    setIsDraggingInsideZone={setIsDraggingInsideZone}
                    setIsFileDraggedInScreen={setIsFileDraggedInScreen}
                />
            </Box>
        </>
    );
};

export { MessageBox };
