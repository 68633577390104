import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { BannerConfiguration, getColor } from './domain';
import './banner.scss';

type Props = {
    settings: BannerConfiguration;
};

const Banner = ({ settings }: Props) => {
    const theme = useTheme();

    return (
        <Box
            bgcolor={() => getColor(settings.theme, theme)}
            paddingLeft={4}
            paddingRight={2}
            className="banner-wrapper"
        >
            <Typography variant="caption" className="banner-text">
                <Trans i18nKey={settings.text} />
            </Typography>
            {settings.icon}
        </Box>
    );
};

export { Banner };
