import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Typography } from '@mui/material';
import LogoRomeu from '@assets/images/romeuWhite.svg?react';
import { Logout } from '../logout';

const Header = () => {
    const { t } = useTranslation();
    return (
        <AppBar color="secondary" position="fixed" sx={{ height: 64, top: 0, userSelect: 'none' }}>
            <Toolbar sx={{ gap: 3 }}>
                <LogoRomeu height={30} title={t('appName')} />
                <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
                    {t('appSurname')}
                </Typography>
                <Logout />
            </Toolbar>
        </AppBar>
    );
};

export { Header };
