import { ChatMessage } from '../domain';

export enum FileStatus {
    ok = 1,
    error = -1,
}

type DocumentFile = {
    id: string;
    fileName: string;
    conversationId: string;
    status: FileStatus;
    errorCode: string;
};

type DocumentUploadResponse = {
    file: DocumentFile;
};

type ConversationRequest = {
    chatWithDocument: boolean;
    conversationId: string;
};

type ConversationResponse = {
    message: ChatMessage;
    id: string;
};

type UploadingStatus = 'started' | 'finished' | 'notUploading' | 'error';

export const getUploadMessage = (status: UploadingStatus, errorCode: string = 'E01') => {
    const messages: Partial<Record<UploadingStatus, ChatMessage>> = {
        started: {
            role: 'document',
            text: 'documents.messages.uploadStarted',
        },
        finished: {
            role: 'document',
            text: 'documents.messages.uploadComplete',
        },
        error: {
            role: 'documentError',
            text: errorCode === 'E02' ? 'documents.messages.uploadErrorContent' : 'documents.messages.uploadError',
        },
    };

    return messages[status];
};

type DocumentBannerConfiguration = {
    show: boolean;
    status: UploadingStatus;
};

export type {
    ConversationRequest,
    ConversationResponse,
    DocumentBannerConfiguration,
    DocumentFile,
    DocumentUploadResponse,
    UploadingStatus,
};
