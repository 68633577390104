import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';
import { EMPTY_GUID } from '../domain';
import { getDownloadUri } from './api';

type Props = {
    chatId: string;
    enabled: boolean;
} & PropsWithChildren;

const DocumentDownload = ({ chatId, enabled, children }: Props) => {
    const onClickHandler = async () => {
        if (chatId !== EMPTY_GUID && enabled) {
            const response = await getDownloadUri(chatId);
            const a = document.createElement('a');
            a.href = response.uri;
            a.click();
        }
    };

    return (
        <Button
            onClick={onClickHandler}
            sx={
                enabled
                    ? {
                          cursor: 'pointer',
                          textTransform: 'unset',
                          p: 0,
                      }
                    : { cursor: 'default', textTransform: 'unset', p: 0 }
            }
            disabled={!enabled}
            fullWidth
        >
            {children}
        </Button>
    );
};

export { DocumentDownload };
