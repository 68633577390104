import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { Markdown } from '@shared/ui';
import { Role } from '../domain';

type Props = {
    role: Role;
    text: string;
};

const MessageElement = ({ role, text }: Props) => {
    const theme = useTheme();

    return role === 'assistant' ? (
        <Box marginTop={1} width="calc(100% - 56px)" sx={{ wordBreak: 'break-word' }}>
            <Markdown text={text} />
        </Box>
    ) : role === 'document' || role === 'documentError' ? (
        <Box
            marginTop={1}
            width="calc(100% - 56px)"
            sx={{
                wordBreak: 'break-word',
                whiteSpace: 'pre-line',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: 1,
                color: role === 'document' ? grey[600] : theme.palette.error.main,
                userSelect: 'none',
            }}
        >
            <DescriptionOutlinedIcon />
            <Typography variant="body2">
                <Trans i18nKey={text} />
            </Typography>
        </Box>
    ) : (
        <Box
            marginTop={1}
            width="calc(100% - 56px)"
            color={role === 'error' ? theme.palette.error.main : 'inherit'}
            sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
        >
            {text}
        </Box>
    );
};

export { MessageElement };
