import { DocumentsSettings } from '@features/settings';

const acceptedFormats = (documentSettings: DocumentsSettings) => {
    return documentSettings.formatsAllowed
        .split(',')
        .map(format => `.${format}`)
        .join(', ');
};

const validateFile = (
    file: File,
    handleErrors: (value: 'size' | 'format', maxSize?: number) => void,
    documentSettings: DocumentsSettings
) => {
    const fileNameSplitted = file.name.split('.');
    const fileFormat = fileNameSplitted.at(-1);

    if (file.size > documentSettings.size * 1000000) {
        handleErrors('size', documentSettings.size);
        return false;
    }

    if (!documentSettings.formatsAllowed.includes(fileFormat!)) {
        handleErrors('format', documentSettings.size);
        return false;
    }

    return true;
};

export { acceptedFormats, validateFile };
