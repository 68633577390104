import { MessageBox, MessagesList, NewChat, NoMessages, useChat } from '@features/chat';
import { DocumentDownload } from '@features/chat/documentDownload';
import { DocumentRemoval } from '@features/chat/documentRemoval';
import { DocumentBannerConfiguration } from '@features/chat/documentUpload';
import { DOC_NAME } from '@features/chat/domain';
import { Footer } from '@features/chat/footer/Footer';
import { Header } from '@features/header';
import { DocumentsSettings, LinksSettings, ModelServiceSettings } from '@features/settings';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, CircularProgress, Slide } from '@mui/material';
import { Banner, BannerConfiguration } from '@shared/ui';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';

const MESSAGE_BOX_INITIAL_HEIGHT = 94;

type Props = {
    links: LinksSettings;
    modelService: ModelServiceSettings;
    documentSettings: DocumentsSettings;
};

const bannerInitState: DocumentBannerConfiguration = {
    show: false,
    status: 'notUploading',
};

const ChatPage = ({ links, modelService, documentSettings }: Props) => {
    const [divMessageBoxHeight, setDivMessageBoxHeight] = useState<number>(MESSAGE_BOX_INITIAL_HEIGHT);
    const [messageBoxFocus, setMessageBoxFocus] = useState<boolean>(false);
    const [disableDocumentUpload, setDisableDocumentUpload] = useState<boolean>(false);
    const [manageBanner, setManageBanner] = useState<DocumentBannerConfiguration>(bannerInitState);
    const [isFileDeleted, setIsFileDeleted] = useState<boolean>(false);
    const containerRef = useRef<HTMLElement>(null);
    const {
        chatId,
        isSendingQuestion,
        isLoading,
        waitingForChatId,
        messages,
        setMessages,
        setChatId,
        sendMessage,
        startNewChat,
    } = useChat();

    const handleStartNewChat = () => {
        startNewChat();
        setMessageBoxFocus(true);
        setDisableDocumentUpload(false);
        setManageBanner(bannerInitState);
    };

    useEffect(() => {
        const fileName = sessionStorage.getItem(DOC_NAME);
        if (fileName !== null && fileName !== '') {
            setManageBanner({ show: true, status: 'finished' });
            setDisableDocumentUpload(true);
        }
    }, []);

    useEffect(() => {
        if (isFileDeleted) {
            setManageBanner({ show: false, status: 'notUploading' });
            sessionStorage.removeItem(DOC_NAME);
            setDisableDocumentUpload(false);
            setIsFileDeleted(false);
        }
    }, [isFileDeleted]);

    const getBannerSettings = () => {
        if (manageBanner.status === 'started') {
            return {
                theme: 'secondary',
                text: t('documents.banner.loading'),
                icon: <CircularProgress size={20} sx={{ color: 'white' }} />,
            } as BannerConfiguration;
        } else {
            const fileName = sessionStorage.getItem(DOC_NAME);
            return {
                theme: 'primary',
                text: t('documents.banner.download', { documentName: fileName }),
                icon: <DownloadIcon />,
            } as BannerConfiguration;
        }
    };

    return (
        <>
            <Header />
            <Box
                height={`calc(100vh - (${divMessageBoxHeight}px + 124px))`}
                display="flex"
                justifyContent="start"
                flexDirection="column"
                alignItems="center"
                marginTop={8}
                overflow="auto"
                fontFamily={'Open Sans'}
                ref={containerRef}
            >
                {!isLoading ? (
                    messages.length > 0 ? (
                        <>
                            {manageBanner.show && (
                                <Slide in={manageBanner.show} container={containerRef.current}>
                                    <Box
                                        display="flex"
                                        justifyContent="start"
                                        alignItems="center"
                                        position="fixed"
                                        top={64}
                                        left={0}
                                        width="100%"
                                        sx={{ zIndex: 2 }}
                                    >
                                        <DocumentDownload chatId={chatId} enabled={manageBanner.status === 'finished'}>
                                            <Banner settings={getBannerSettings()} />
                                        </DocumentDownload>
                                        {manageBanner.status === 'finished' && (
                                            <DocumentRemoval
                                                conversationId={chatId}
                                                setIsDeleted={setIsFileDeleted}
                                                fileName={sessionStorage.getItem(DOC_NAME)!}
                                                setMessages={setMessages}
                                            />
                                        )}
                                    </Box>
                                </Slide>
                            )}
                            <MessagesList
                                messages={messages}
                                isSendingQuestion={isSendingQuestion}
                                isBannerVisible={manageBanner.show}
                            />
                        </>
                    ) : (
                        <NoMessages />
                    )
                ) : (
                    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress sx={{ minWidth: 100, minHeight: 100 }} />
                    </Box>
                )}
                <MessageBox
                    sendMessage={sendMessage}
                    isSendingQuestion={isSendingQuestion || waitingForChatId}
                    maxTokens={modelService.maxTokens}
                    tokenCounterInfoUrl={modelService.urlTokenCounterInfo}
                    setDivMessageBoxHeight={setDivMessageBoxHeight}
                    isFocused={messageBoxFocus}
                    setIsFocused={setMessageBoxFocus}
                    documentSettings={documentSettings}
                    chatId={chatId}
                    setChatId={setChatId}
                    setMessages={setMessages}
                    setManageBanner={setManageBanner}
                    disableDocumentUpload={disableDocumentUpload}
                    setDisableDocumentUpload={setDisableDocumentUpload}
                />
                <Footer links={links} />
                <NewChat startNewChat={handleStartNewChat} isDisabled={messages.length === 0} />
            </Box>
        </>
    );
};
export { ChatPage };
